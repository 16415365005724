import { useStyletron } from 'baseui'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faExclamationTriangle, faTools, faUser } from '@fortawesome/free-solid-svg-icons'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { Grid, Cell } from 'baseui/layout-grid'
import { ourColors } from './Colors'
import { StyleObject } from 'styletron-standard'
import { Link } from 'gatsby'

interface NavProps {
  header: boolean
}

const VerticalRule = () => {
  const [css] = useStyletron()

  return (
    <div
      className={css({
        position: 'absolute',
        backgroundColor: 'black',
        borderRight: `1px solid ${ourColors.lineGrey}`,
        right: 0,
        top: '5px',
        bottom: '5px'
      })}
    />
  )
}

interface NavButtonProps {
  icon: IconDefinition
  text: string
  url: string
  testId: string
}

const NavButton = ({ icon, text, url, testId }: NavButtonProps) => {
  const [css] = useStyletron()

  return (
    <Link
      key={text}
      to={url}
      data-testid={testId}
      className={css({
        textDecoration: 'none'
      })}
    >
      <div
        className={css({
          backgroundColor: ourColors.webBlack,
          ':hover': {
            backgroundColor: ourColors.hoverGrey
          },
          fontFamily: 'Bitter',
          height: '50px',
          color: 'white',
          textAlign: 'center',
          marginTop: 'auto',
          marginBottom: 'auto',
          marginLeft: 'auto',
          marginRight: 'auto',
          paddingTop: '5px',
          paddingBottom: '5px'
        })}
      >
        <FontAwesomeIcon icon={icon} className={css({ height: '16px', fontSize: '16px' })} />
        <div className={css({ fontWeight: 'normal', fontSize: '12px', lineHeight: '15px' })}>
          {text}
        </div>
      </div>
    </Link>
  )
}

const navStyles = (header: boolean) => {
  const navStyles: StyleObject = {
    display: 'flex',
    backgroundColor: ourColors.webBlack
  }
  if (header) {
    navStyles['@media screen and (max-width: 599px)'] = { display: 'none' }
  } else {
    navStyles.position = 'fixed'
    navStyles.left = '0'
    navStyles.right = '0'
    navStyles.bottom = '0'
    navStyles['@media screen and (min-width: 600px)'] = {
      display: 'none'
    }
  }
  return navStyles
}

const testId = (name: string, header: boolean) => {
  return name + 'Nav' + (header ? 'Header' : '')
}

export const Nav = ({ header }: NavProps) => {
  const [css, theme] = useStyletron()
  const buttonContent = [
    { icon: faHome, text: 'HOME', url: '/', testId: testId('home', header) },
    {
      icon: faExclamationTriangle,
      text: 'HAZARDS',
      url: '/hazard-home',
      testId: testId('hazards', header)
    },
    { icon: faTools, text: 'TOOLS', url: '/tools-home', testId: testId('tools', header) },
    { icon: faUser, text: 'ACCOUNT', url: '/account/view', testId: testId('account', header) }
  ]
  const buttonCount = buttonContent.length

  return (
    <Grid gridMargins={0} gridGutters={0} gridMaxWidth={theme.grid.maxWidth}>
      <Cell span={[4, 6]}>
        <nav className={css(navStyles(header))}>
          {buttonContent.map((value, index) => {
            return (
              <div key={value.text} className={css({ width: '25%', position: 'relative' })}>
                <NavButton
                  text={value.text}
                  icon={value.icon}
                  url={value.url}
                  testId={value.testId}
                />
                {index < buttonCount - 1 && <VerticalRule />}
              </div>
            )
          })}
        </nav>
      </Cell>
    </Grid>
  )
}

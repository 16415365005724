import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { Header } from './Header'
import { Nav } from './Nav'
import './layout.css'
import { BaseProvider, createTheme, lightThemePrimitives, useStyletron } from 'baseui'
import { ourColors } from './Colors'
import { ErrorBoundary } from './ErrorBoundary'
import { LoginResponse, Role } from '../api/user'
import { ContentContainer } from './ContentContainer'
import useInspectlet from '../hooks/inspectlet'

export const theme = createTheme(
  {
    ...lightThemePrimitives,
    // Accent Palette
    accent: ourColors.utechBlue,
    accent50: ourColors.utechBlue,
    accent100: ourColors.utechBlue,
    accent200: ourColors.utechBlue,
    accent300: ourColors.utechBlue,
    accent400: ourColors.utechBlue,
    accent500: ourColors.utechBlue,
    accent600: ourColors.utechBlue,
    accent700: ourColors.utechBlue,
    primaryFontFamily: 'Roboto, sans-serif'
  },
  {
    colors: {
      contentPrimary: ourColors.copyGrey,
      inputEnhancerFill: ourColors.interfaceGrey,
      inputFillActive: ourColors.white,
      borderFocus: ourColors.webBlack,
      inputFill: ourColors.white,
      buttonPrimaryHover: ourColors.hoverGrey,
      buttonPrimaryActive: ourColors.hoverGrey,
      buttonSecondaryFill: ourColors.utechBlue,
      buttonSecondaryText: ourColors.white,
      buttonSecondaryHover: ourColors.utechLighterBlue,
      buttonSecondaryActive: ourColors.utechLighterBlue,
      buttonSecondarySelectedText: ourColors.white,
      buttonSecondarySelectedFill: ourColors.utechLighterBlue,
      buttonTertiaryFill: ourColors.copyGrey,
      buttonTertiaryText: ourColors.white,
      buttonTertiaryHover: ourColors.lighterCopyGrey,
      buttonTertiaryActive: ourColors.lighterCopyGrey,
      buttonTertiarySelectedText: ourColors.white,
      buttonTertiarySelectedFill: ourColors.lighterCopyGrey,
      tickFill: ourColors.white,
      tickFillActive: ourColors.lightGrey,
      tickFillHover: ourColors.lightGrey,
      tickFillSelected: ourColors.utechOrange,
      tickFillSelectedHover: ourColors.utechLightOrange,
      tickFillSelectedHoverActive: ourColors.utechLightOrange,
      inputBorder: ourColors.interfaceGrey,
      inputPlaceholder: ourColors.placeholderGrey,
      linkText: ourColors.copyGrey
    },
    borders: {
      inputBorderRadius: '5px',
      buttonBorderRadius: '5px'
    },
    sizing: {
      scale0: '1px'
    },
    typography: {
      font200: {
        fontFamily: 'Roboto',
        fontSize: '18px',
        lineHeight: '28px',
        color: ourColors.copyGrey
      },
      font250: {
        lineHeight: '28px',
        fontWeight: 'normal',
        fontSize: '18px'
      },
      LabelLarge: {
        fontSize: '18px',
        lineHeight: '24px',
        fontFamily: 'Roboto',
        fontWeight: 'bold'
      },
      HeadingMedium: {
        fontSize: '16px',
        lineHeight: '28px',
        fontFamily: 'Montserrat',
        fontWeight: 'bold'
      },
      HeadingLarge: {
        fontSize: '18px',
        lineHeight: '28px',
        fontFamily: 'Montserrat',
        fontWeight: 'bold'
      },
      HeadingXXLarge: {
        lineHeight: '36px',
        fontSize: '26px',
        fontFamily: 'Montserrat',
        fontWeight: 'bold'
      },
      HeadingXLarge: {
        lineHeight: '36px',
        fontSize: '26px',
        fontFamily: 'Montserrat',
        fontWeight: 'bold'
      }
    },
    grid: {
      columns: [4, 6, 6],
      margins: [16, 16, 16],
      gutters: [16, 16, 16],
      maxWidth: 658
    }
  }
)

interface LayoutProps {
  readonly children?: React.ReactNode | readonly React.ReactNode[]
  searchBarOpen?: boolean
  searchBarAvailable?: boolean
  showNav?: boolean
  backButton?: boolean
}

interface RenderData {
  readonly site: {
    readonly siteMetadata: {
      readonly title: string
    }
  }
}

export type SetUserContextFunction =
  | ((loginResponse: LoginResponse | null, rememberMe: boolean) => void)
  | null
export interface UserContextInterface {
  email: string | null
  preferredName: string | null
  name: string | null
  userId: number | null
  token: string | null
  role: Role | null
  union: string | null
  unionName: string | null
  rememberMe: boolean | null
  hasCreatedIssue: boolean | null
  setUserContext: SetUserContextFunction
}
export const UserContext = React.createContext({
  email: null,
  preferredName: null,
  role: null,
  union: null,
  unionName: null,
  name: null,
  userId: null,
  token: null,
  rememberMe: null,
  hasCreatedIssue: null,
  setUserContext: null
} as UserContextInterface)

const getSavedUserContext = (): UserContextInterface => {
  if (typeof window !== 'undefined') {
    const session = window.sessionStorage.getItem('user')
    const local = window.localStorage.getItem('user')
    if (local) {
      return {
        ...JSON.parse(local),
        setUserContext: null,
        rememberMe: true
      }
    } else if (session) {
      return {
        ...JSON.parse(session),
        setUserContext: null,
        rememberMe: false
      }
    }
  }
  return {
    email: null,
    preferredName: null,
    role: null,
    union: null,
    unionName: null,
    name: null,
    userId: null,
    token: null,
    rememberMe: null,
    hasCreatedIssue: null,
    setUserContext: null
  }
}

// This is an attempt to do the same thing on the react context api example but in
// a functional component.
// See the original example here: https://reactjs.org/docs/context.html#updating-context-from-a-nested-component
const bootstrapUserContext = (
  setUserContextState: (value: React.SetStateAction<UserContextInterface>) => void,
  userContext: UserContextInterface
) => {
  // if there is no setUserContext state set, we want to bootstrap it by setting the
  // setUserContext function
  if (!userContext.setUserContext) {
    setUserContextState(oldState => {
      return {
        ...oldState,
        setUserContext: (loginResponse, rememberMe) => {
          setUserContextState(prevState => {
            if (loginResponse && loginResponse.user && loginResponse.key) {
              return {
                ...prevState,
                token: loginResponse.key,
                userId: loginResponse.user.pk,
                email: loginResponse.user.email,
                preferredName: loginResponse.user.preferred_name,
                role: loginResponse.user.role,
                union: loginResponse.user.union,
                unionName: loginResponse.user.union_name,
                name: loginResponse.user.name,
                hasCreatedIssue: loginResponse.user.has_created_issue,
                rememberMe: rememberMe
              }
            } else {
              return {
                email: null,
                preferredName: null,
                role: null,
                union: null,
                unionName: null,
                name: null,
                userId: null,
                token: null,
                rememberMe: null,
                hasCreatedIssue: null,
                setUserContext: prevState.setUserContext
              }
            }
          })
        }
      }
    })
  }
}

interface VisibleContentProps {
  backButton: boolean
  showNav: boolean
  searchBarOpen: boolean
  searchBarAvailable: boolean
  readonly children?: React.ReactNode | readonly React.ReactNode[]
}

export const VisibleContent = (props: VisibleContentProps) => {
  const [css] = useStyletron()

  const navFooter = (
    <>
      <div
        className={css({
          marginBottom: '55px',
          '@media screen and (min-width: 600px)': { marginBottom: 0 }
        })}
      />
      <Nav header={false} />
    </>
  )
  return (
    <>
      <Header
        showNav={props.showNav}
        searchBarOpen={props.searchBarOpen}
        searchBarAvailable={props.searchBarAvailable}
        backButton={props.backButton}
      />
      <ContentContainer>{props.children}</ContentContainer>
      {props.showNav && navFooter}
    </>
  )
}

const Layout: React.StatelessComponent<LayoutProps> = props => {
  const [userContext, setUserContextState] = React.useState(getSavedUserContext)
  useInspectlet()
  bootstrapUserContext(setUserContextState, userContext)

  React.useEffect(() => {
    const toSave = JSON.stringify({
      token: userContext.token,
      userId: userContext.userId,
      email: userContext.email,
      preferredName: userContext.preferredName,
      role: userContext.role,
      union: userContext.union,
      unionName: userContext.unionName,
      name: userContext.name,
      hasCreatedIssue: userContext.hasCreatedIssue
    })
    if (userContext.rememberMe) {
      window.localStorage.setItem('user', toSave)
    } else {
      window.sessionStorage.setItem('user', toSave)
    }
  }, [userContext])

  const BaseLayout = (
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={(data: RenderData) => (
        <BaseProvider theme={theme}>
          <UserContext.Provider value={userContext}>
            <Helmet
              titleTemplate={`%s - ${data.site.siteMetadata.title}`}
              defaultTitle={data.site.siteMetadata.title}
              meta={[
                {
                  name: 'description',
                  content: 'OHS Help'
                },
                {
                  name: 'keywords',
                  content: 'OHS, health and safety'
                }
              ]}
            />
            <VisibleContent
              // Need to cast below because they need to be optional
              // for defaultProps to work
              backButton={props.backButton as boolean}
              showNav={props.showNav as boolean}
              searchBarOpen={props.searchBarOpen as boolean}
              searchBarAvailable={props.searchBarAvailable as boolean}
            >
              {props.children}
            </VisibleContent>
          </UserContext.Provider>
        </BaseProvider>
      )}
    />
  )
  if (process.env.NODE_ENV === 'production') {
    return <ErrorBoundary>{BaseLayout}</ErrorBoundary>
  }
  return BaseLayout
}
Layout.defaultProps = {
  backButton: true,
  showNav: true,
  searchBarOpen: false,
  searchBarAvailable: true
}
export { Layout }

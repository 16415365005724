import React from 'react'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

interface State {
  error: boolean
}

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { error: false }
  }

  componentDidCatch(error: Error, errorInfo: any) {
    this.setState({ error: true })
    // eslint-disable-next-line no-undef
    Sentry.configureScope((scope: any) => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key])
      })
    })
    // eslint-disable-next-line no-undef
    Sentry.captureException(error)
  }

  componentDidMount() {
    // Do not load sentry more than once
    if (!document.getElementById('sentry')) {
      const includeSentry = document.createElement('script')

      includeSentry.src = 'https://browser.sentry-cdn.com/5.11.0/bundle.min.js'
      includeSentry.id = 'sentry'
      includeSentry.async = true
      includeSentry.integrity =
        'sha384-jbFinqIbKkHNg+QL+yxB4VrBC0EAPTuaLGeRT0T+NfEV89YC6u1bKxHLwoo+/xxY'
      includeSentry.crossOrigin = 'anonymous'

      document.body.appendChild(includeSentry)

      const configSentry = document.createElement('script')

      configSentry.text =
        "Sentry.init({ dsn: 'https://838395c0e9794b9c943c1d893f9a5f23@sentry.io/1889352' });"
      document.body.appendChild(configSentry)
    }
  }

  render() {
    if (this.state.error) {
      // render fallback UI
      return <h1>Something went wrong!</h1>
    } else {
      return this.props.children
    }
  }
}

import { Button as BaseButton, ButtonProps, ButtonOverrides } from 'baseui/button'
import { withOverrides } from './overrides'

const globalOverrides: ButtonOverrides = {
  BaseButton: {
    style: {
      minWidth: '33%',
      width: '50%',
      maxWidth: '250px',
      fontSize: '18px',
      lineHeight: 'normal',
      fontFamily: 'Roboto, sans-serif',
      marginTop: '36px'
    }
  }
}

export const Button = withOverrides<ButtonProps>(BaseButton, globalOverrides, true)

import React from 'react'
import { Input } from './Input'
import { Button } from './Button'
import { Nav } from './Nav'

import { Grid, Cell, ALIGNMENT } from 'baseui/layout-grid'
import { navigate, Link } from 'gatsby'
import { useStyletron } from 'baseui'
import { ourColors } from './Colors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'

import Logo from '../svg/ohshelp-white.svg'

const toggleButtonStyles = {
  paddingTop: '7px',
  paddingLeft: '5px',
  paddingRight: '5px',
  border: 'none',
  backgroundColor: ourColors.utechOrange,
  justifyContent: 'flex-start',
  ':hover': {
    cursor: 'pointer'
  },
  '@media only screen and (min-width: 700px)': {
    width: '47px'
  }
}

interface SearchBarToggleProps {
  showSearchBar: boolean
  setShowSearchBar: Function
}

export const SearchBarToggle = ({ showSearchBar, setShowSearchBar }: SearchBarToggleProps) => {
  const [css] = useStyletron()

  const toggleSearchBar = () => {
    setShowSearchBar(!showSearchBar)
  }

  return (
    <button
      className={css(toggleButtonStyles)}
      onClick={toggleSearchBar}
      data-testid="search-bar-toggle"
      aria-label={showSearchBar ? 'Close search bar' : 'Open search bar'}
    >
      <div
        className={css({
          fontSize: '28px'
        })}
      >
        {showSearchBar && <FontAwesomeIcon icon={faTimes} color="white" />}
        {!showSearchBar && <FontAwesomeIcon icon={faSearch} color="white" />}
      </div>
    </button>
  )
}

const BackButton = () => {
  const [css] = useStyletron()

  return (
    <button
      data-testid="back-button"
      className={css({
        ...toggleButtonStyles,
        ...{ paddingTop: '3px', paddingLeft: '2px', paddingRight: '2px' }
      })}
      onClick={() => navigate(-1)}
    >
      <FontAwesomeIcon
        className={css({
          fontSize: '34px',
          alignSelf: 'center'
        })}
        icon={faChevronLeft}
        color="white"
      />
    </button>
  )
}

const EndSearchBarToggle = (props: SearchBarToggleProps) => {
  const [css] = useStyletron()

  return (
    <div
      className={css({
        display: 'flex',
        justifyContent: 'flex-end',
        '@media only screen and (min-width: 700px)': {
          paddingRight: '10px'
        }
      })}
    >
      <SearchBarToggle
        showSearchBar={props.showSearchBar}
        setShowSearchBar={props.setShowSearchBar}
      />
    </div>
  )
}

interface SearchBoxProps {
  searchText: string
  setSearchText: React.Dispatch<React.SetStateAction<string>>
}
export const SearchBox = ({ searchText, setSearchText }: SearchBoxProps) => {
  const [css] = useStyletron()

  return (
    <div className={css({ marginTop: '18px', marginBottom: '18px', marginLeft: '2px' })}>
      <Input
        value={searchText}
        onChange={e => setSearchText(e.currentTarget.value)}
        placeholder="Search hazards"
      />
    </div>
  )
}
interface SearchButtonProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => any
}
export const SearchButton = ({ onClick }: SearchButtonProps) => {
  const [css] = useStyletron()

  return (
    <div
      className={css({
        marginTop: '18px',
        marginBottom: '18px',
        marginRight: '3px',
        flexGrow: 1,
        display: 'flex'
      })}
    >
      <Button
        kind="secondary"
        data-testid="search-button"
        overrides={{
          BaseButton: {
            style: {
              backgroundColor: ourColors.utechBlue,
              marginTop: '0px',
              flexGrow: 1,
              height: '51px'
            }
          }
        }}
        onClick={onClick}
      >
        Go
      </Button>
    </div>
  )
}

export const SearchBar = () => {
  const [searchText, setSearchText] = React.useState('')
  const [css, theme] = useStyletron()

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    navigate('/search-results?search=' + searchText)
  }

  return (
    <form
      onSubmit={handleSubmit}
      className={css({
        marginBottom: 0
      })}
    >
      <div
        className={css({
          backgroundColor: ourColors.utechOrange,
          maxWidth: `${theme.grid.maxWidth}px`,
          marginLeft: 'auto',
          marginRight: 'auto'
        })}
      >
        <Grid gridMargins={14} gridGutters={[16, 16]}>
          <Cell span={[3, 5]} align={ALIGNMENT.center}>
            <SearchBox searchText={searchText} setSearchText={setSearchText} />
          </Cell>
          <Cell span={[1, 1]}>
            <SearchButton onClick={handleSubmit} />
          </Cell>
        </Grid>
      </div>
    </form>
  )
}

interface HeaderProps {
  searchBarOpen: boolean
  searchBarAvailable: boolean
  showNav: boolean
  backButton: boolean
}

export const Header = (props: HeaderProps) => {
  const [searchBarOpen, setSearchBarOpen] = React.useState(props.searchBarOpen)

  const [css, $theme] = useStyletron()
  const logoStyles = css({
    paddingTop: '16px',
    paddingBottom: '5px',
    textAlign: 'center'
  })

  return (
    <header>
      <div
        className={css({
          background: ourColors.utechOrange
        })}
      >
        <Grid>
          <Cell span={[1, 1]} align={ALIGNMENT.center}>
            {props.backButton && <BackButton />}
          </Cell>
          <Cell span={[2, 4]} align={ALIGNMENT.center}>
            <div className={logoStyles}>
              <Link to="/">
                <Logo />
              </Link>
            </div>
          </Cell>
          <Cell span={[1, 1]} align={ALIGNMENT.center}>
            {props.searchBarAvailable && (
              <EndSearchBarToggle
                showSearchBar={searchBarOpen}
                setShowSearchBar={setSearchBarOpen}
              />
            )}
          </Cell>
          <Cell span={[4, 6]}>
            <div
              className={css({
                left: 0,
                right: 0,
                position: 'absolute',
                borderBottom: `${$theme.sizing.scale0} solid white`,
                zIndex: 0
              })}
            />
          </Cell>
        </Grid>
      </div>

      {searchBarOpen && props.searchBarAvailable && <SearchBar />}
      {props.showNav && <Nav header={true} />}
    </header>
  )
}

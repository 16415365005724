export const ourColors = {
  utechBlue: '#003b74',
  utechLighterBlue: '#005CB2',
  utechOrange: '#F56A19',
  utechPink: '#ed1261',
  utechLightOrange: '#f18a2a',
  lightGrey: '#EAEAEA',
  interfaceGrey: '#979797',
  lighterCopyGrey: '#767676',
  lineGrey: '#C8C8C8',
  hoverGrey: '#757575',
  copyGrey: '#505054',
  webBlack: '#333333',
  white: '#FFFFFF',
  backgroundGrey: '#F4F4F4',
  placeholderGrey: '#8C8C8C',
  tableHeaderGrey: '#D8D8D8',
  scaleRed: '#C62639',
  scaleAmber: '#E88939',
  scaleGreen: '#008A48'
}

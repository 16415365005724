import { useStyletron } from 'baseui'
import React from 'react'
import { ourColors } from './Colors'

interface ContentContainerProps {
  readonly children?: React.ReactNode | readonly React.ReactNode[]
}

export const ContentContainer = ({ children }: ContentContainerProps) => {
  const [css, theme] = useStyletron()

  return (
    <div
      className={css({
        backgroundColor: ourColors.white,
        maxWidth: `${theme.grid.maxWidth}px`,
        marginLeft: 'auto',
        marginRight: 'auto',
        minHeight: '100vh'
      })}
    >
      {children}
    </div>
  )
}

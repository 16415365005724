import {
  StatefulInput as BaseStatefulInput,
  Input as BaseInput,
  InputProps,
  InputOverrides
} from 'baseui/input'
import {
  StatefulTextarea as BaseStatefulTextarea,
  Textarea as BaseTextarea,
  TextareaProps
} from 'baseui/textarea'
import { StyleObject } from 'styletron-react'
import { ourColors } from './Colors'
import { withOverrides } from './overrides'

export const globalOverrides: InputOverrides = {
  InputContainer: {
    style: ({ $isFocused, $theme }) => {
      const style: StyleObject = {
        borderTopWidth: $theme.sizing.scale0,
        borderBottomWidth: $theme.sizing.scale0,
        borderLeftWidth: $theme.sizing.scale0,
        borderRightWidth: $theme.sizing.scale0
      }
      if (!$isFocused) {
        style.borderTopColor = ourColors.interfaceGrey
        style.borderBottomColor = ourColors.interfaceGrey
        style.borderLeftColor = ourColors.interfaceGrey
        style.borderRightColor = ourColors.interfaceGrey
      }
      return style
    }
  },
  Input: {
    style: {
      fontSize: '18px',
      lineHeight: '22px',
      height: '49px',
      paddingLeft: '12px',
      '::placeholder': {
        fontStyle: 'italic',
        fontSize: '18px',
        opacity: 1
      }
    }
  }
}

export const Input = withOverrides<InputProps>(BaseInput, globalOverrides)
export const Textarea = withOverrides<TextareaProps>(BaseTextarea, globalOverrides)
export const StatefulInput = withOverrides<InputProps>(BaseStatefulInput, globalOverrides)
export const StatefulTextarea = withOverrides<TextareaProps>(BaseStatefulTextarea, globalOverrides)

import { useEffect } from 'react'

const useInspectlet = () => {
  useEffect(() => {
    if (process.env.GATSBY_ANALYTICS === 'true') {
      /* eslint-disable */
      ;(<any>window).__insp = (<any>window).__insp || []
      // @ts-ignore
      __insp.push(['wid', 1355367311])
      let ldinsp = function() {
        if (typeof (<any>window).__inspld != 'undefined') return
        ;(<any>window).__inspld = 1
        var insp = document.createElement('script')
        insp.type = 'text/javascript'
        insp.async = true
        insp.id = 'inspsync'
        insp.src =
          ('https:' == document.location.protocol ? 'https' : 'http') +
          '://cdn.inspectlet.com/inspectlet.js?wid=1355367311&r=' +
          Math.floor(new Date().getTime() / 3600000)
        var x = document.getElementsByTagName('script')[0]
        if (x && x.parentNode) {
          x.parentNode.insertBefore(insp, x)
        }
      }
      setTimeout(ldinsp, 0)
    }
  }, [])
  /* eslint-enable */
}

export default useInspectlet
